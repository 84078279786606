import React from 'react';



const Download = (props:{pdfId:any,name:any}) => {

    DownloadPDF(props.pdfId,props.name);

    return (
        <>
            <div id={'download'}></div>
        </>
    )


};


export default Download;



const DownloadPDF = (pdfID:any,name:any) => {
    let api_URL;
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        api_URL = process.env.REACT_APP_API_URL_LOCAL;
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        api_URL = process.env.REACT_APP_API_URL_LIVE;
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
        api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
    }
    let token = localStorage.getItem('token');
    fetch(api_URL + 'Media/' + pdfID + '/', {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + token
        },

    })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.innerHTML = '<span class="material-icons" style="vertical-Align: -20%; color: red">picture_as_pdf</span>'+name;
            a.setAttribute('download', name);
            a.setAttribute('target', '_blank');

            let div = document.getElementById('download') as HTMLDivElement
            if (div !== null) {
                if (!div.hasChildNodes()) {
                    div.appendChild(a);
                }
            }
        })
}



