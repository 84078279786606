import {useEffect, useState} from 'react';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import {allUnitApiType} from '../../request/unit/allUnitApi.interface';
import {Unit} from '../../request/unit/Unit';
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseallUnitLimit = (page: any, searchValue: any) => {
  const [posts, setPosts] = useState<allUnitApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Unit.getallUnitLimit(page,searchValue)
        .then((data) => {
          checkLogin(data);
          setPosts(data);
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [page,searchValue])


  return isError ? (isError ) : (posts);

}
export const ResponseallUnitDetail = (page:any) => {
  const [posts, setPosts] = useState<allUnitApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Unit.getAPost(page)
        .then((data) => {
          checkLogin(data);
          setPosts(data);
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [page])


  return isError ? (isError ) : (posts);

}

export const ResponseUnitDelete = (id: string) => {
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Unit.deletePostUnit(id)
        .then((data) => {
          checkLogin(data);
          return data;
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (isError);

}




