import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize';
import {config, output} from './configuration/mapping';
import PielersForm from '../../../../@WUM/core/form-serilizer';
import configuration from './configuration/Page/configuration';
import Spinner from 'react-bootstrap/Spinner';
import {notify_save} from '../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../@WUM/core/component/const/error.services';
import {handleSubmitForm} from '../../../../@WUM/core/component/const/handleSubmitForm';
import {btn_back} from '../../../../@WUM/core/component/const/btn_back';
import {Form} from 'react-bootstrap';
import {ResponseallUnitDetail} from '../API/response/Unit/Unit';
import {allUnitApiType} from '../API/request/unit/allUnitApi.interface';
import {Unit} from '../API/request/unit/Unit';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";



const UnitDetail = (value:any) => {
  const Store = useSelector((state: any) => state)
  const {id} = useParams<{id: string}>();
  const response2 = ResponseallUnitDetail(id);
  const history = useNavigate();

  /**
   * Neuladen der Seite
   */
  const routeChange = (path: string) => {
    let paths = path;
    history(paths);
  };

  const [currentTutorial, setCurrentTutorial] = useState<allUnitApiType[]>((response2 !== true ? response2 : []));
  const [validated, setValidated] = useState(false);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init();
    let reauestData: allUnitApiType[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));

    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    Unit.updatePost(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        routeChange(`/Unit/Detail/` + id);
      })
      .catch(e => {
        error(e);
      });
  };



  if (response2.hasOwnProperty('code') === true) {
    return (
      <div>
        <Form  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, id)}>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
            <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(configuration('dataColOne', response2, false,Store))}
            </Tab>
{/*            <Tab eventKey="produkte" title="Produkte" key={'produkte'} id={'produkte'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
              <h3>Folgende Produkte sind dieser Kategorie zugeordnet</h3>
              {allproduct(response2)}
            </Tab>*/}
          </Tabs>
          <div style={{backgroundColor: 'white', padding: '15px'}}>
            {btn_back(history,'common.abort','',Store)}
            <Button variant='success'
                    style={{float: 'right', marginRight: '15px'}}
                    type={'submit'}
            >
              <IntlMessage Store={Store} messageId="common.save" />
            </Button>
            <br style={{clear: 'both'}} />
          </div>
        </Form>
      </div>
    );
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner></>
    )
  }
}


export default UnitDetail;
