export interface config {
  name: string;
  description: string;
  code: string;
}

export function output(Data: any) {
  let back = {
    name: Data.name,
    description: Data.description,
    code: Data.code,


  }

  return back;
}