import React from 'react';
import {LanguageSwitcherValue} from "../../../core/components/LanguageSwitcher";
import MerchantOverview from "./Pages/overview";
import MerchantDetail from "./Pages/Detail";
import DashbboardOverview from "./Pages/dashboard";

export const merchantPagesConfig = (value:any) =>
{
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'ProductCatalog' || 'ProductCatalogwithMatomo':
            return [
                {
                    path: '/merchant/Detail/:id',
                    elements: <MerchantDetail value={value} />,
                },
            ];
        default:
            return [
                {
                    path: '/merchant/overview/:page',
                    elements: <MerchantOverview value={value} />,
                },
                {
                    path: '/merchant/Detail/:id',
                    elements: <MerchantDetail value={value} />,
                },
                {
                    path: '/merchant/Dashboard',
                    elements: <DashbboardOverview value={value} />,
                },
            ];
    }

}

export const merchantMenuConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'ProductCatalog' || 'ProductCatalogwithMatomo':
            return [
                {
                    name: 'Profil',
                    route: '/merchant/Detail/'+process.env.REACT_APP_MERCHANT_PROFIL,
                    icon: 'storefront'
                }
            ]
        default:
            return [
                {
                    name: LanguageSwitcherValue("shop.merchant.header"),
                    route: '/merchant/overview/1',
                    icon: 'storefront'
                }
            ]
    }
}
