
export interface config {
  name: string;
  code: string;
  description: string;
}

export function output(Data: any) {
  let back = {
    name:  Data.name,
    code: Data.code,
    description:  Data.description,
  }

  return back;
}