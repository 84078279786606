import React from 'react';
import {LanguageSwitcherValue} from "../../../core/components/LanguageSwitcher";
import ProductOverview from "./Pages/Productoverview";
import ProductDetail from "./Pages/ProductDetail";
import ProductNew from "./Pages/new";

export const ProductPagesConfig = (value: any) => {

  return [
      {
        path: '/Product/overview/:page',
        elements: <ProductOverview value={value} />,
      },
      {
        path: '/Product/Detail/:id',
        elements: <ProductDetail value={value} />,
      },
      {
        path: '/Product/Show/:id',
        elements: <ProductDetail value={value} />,
      },
      {
        path: '/Product/Delete/:id',
        elements: <ProductDetail value={value} />,
      },
      {
        path: '/Product/new/new',
        elements: <ProductNew value={value} />,
      },
];
}

export const ProductMenuConfig = (value: any) => {
  return [
    {
      name: LanguageSwitcherValue("shop.product.header"),
      route: '/Product/overview/1',
      icon: 'shopping_bag'
    }
  ]
}
