
import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {allTaxonApiType} from './allTacxonApi.interface';


export const Taxon = {
  getallTaxonLimit: (page:any,search:any): Promise<allTaxonApiType[]> => requests.get(`/Product/allTaxon/${page}/${search}`),
  getallTaxonTree: (): Promise<allTaxonApiType[]> => requests.get(`TaxonTree/`),
  getAPost: (id: string): Promise<allTaxonApiType[]> =>  requests.get(`SingleTaxon/${id}/`),
  updatePost: (post: any, id: number): Promise<allTaxonApiType> =>
    requests.put(`SingleTaxon/update/${id}/`, post),
  updatePostNew: (post: any, id: number): Promise<allTaxonApiType> =>
    requests.put(`SingleTaxon/insert/new/`, post),
};



