import SunEditor,{buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import React from 'react';
import {apiRoot} from "../../RestFullApi/ApiHook";

function WYSIWYGindi(data: any, i: number, indi: any = undefined,_handleKeyDown:any) {

    console.log(data)
    return (<><div>
      <br /><label>{indi.label}</label><SunEditor name={indi.name} lang={indi.lang} defaultValue={indi.value} key={'Sun' + i} height={indi.height} onClick={content => {
      _handleKeyDown(indi.value)}} onChange={content => {
      _handleKeyDown(content);
    }}  setOptions={{
        "imageWidth": "150px",
        "imageHeight": "150px",
        "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
        "imageGalleryHeader": {
            "Authorization": "Bearer "+localStorage.getItem('token')
        },
        buttonList: buttonList.complex,
        attributesWhitelist:
            {
                div:'id|class',
                p: 'id|class',
                h: 'id|class',
                span: 'id|class'
            },
        "linkNoPrefix": true,
    }}/> </div></>)
}

export default WYSIWYGindi;
