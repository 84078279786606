import React from 'react';
import PielersForm from '../../index'
import {Tabs,Button} from 'react-bootstrap';
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import ReactDOM from "react-dom";
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
function sliderWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': 'Slider Name',
      'name': 'elements[' + index + '][translation][name]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),
    },
    {
      'formType': 'input',
      'label': 'Slider Class',
      'name': 'elements[' + index + '][translation][class_carousel]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.class_carousel : '') : ''),
    },
    {
      'formType': 'input',
      'label': 'inner Carousel Class',
      'name': 'elements[' + index + '][translation][innercarousel]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.innercarousel : '') : ''),
    },
    {
      'formType': 'select',
      'label': 'Intervall',
      'br': true,
      'name': 'elements[' + index + '][translation][intervall]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.intervall : '') : ''),
      'options': [
        {
          'label': '1 sec.',
          'value': '1000'
        },
        {
          'label': '2 sec.',
          'value': '2000'
        },
        {
          'label': '3 sec.',
          'value': '3000'
        },
        {
          'label': '4 sec.',
          'value': '4000'
        },
        {
          'label': '5 sec.',
          'value': '5000'
        },
        {
          'label': '6 sec.',
          'value': '6000'
        },
        {
          'label': '7 sec.',
          'value': '7000'
        },
        {
          'label': '8 sec.',
          'value': '8000'
        },
        {
          'label': '9 sec.',
          'value': '9000'
        },
        {
          'label': '10 sec.',
          'value': '10000'
        }]

    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/slider.html',
    },
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }

  function JSONNewAppend() {

    let JsonP = JSON.parse((document.getElementById('json' + index) as HTMLInputElement).value);

    console.log(JsonP.item);

    let newPart = {
      'name': '',
      'alt': '',
      'link':'',
      'text':'',
      'id': null,
      'parent': 0,
      'locale': 'de',
    };

    let tmpItem = [];

    for(let x=0;x<JsonP.item.length;x++) {
      tmpItem.push(JsonP.item[x])
    }
    tmpItem.push(newPart);

    JsonP.item = tmpItem;

    (document.getElementById('json' + index) as HTMLInputElement).value = JSON.stringify(JsonP);

    let conf = [
      {
        'formType': 'multiply',
        'RowId':'slide'+(JsonP.item.length -1)+'_'+index,
        'cols': [
          {
            'formType': 'image',
            'altLink': true,
            'id': 'Logo_Image'+(JsonP.item.length -1)+'_'+index,
            'path': 'test',
            'width': '44',
            'height': '44',
            'class': 'elementsIMg',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          }, {
            'formType': 'formFile',
            'onclick': true,
            'id': 'logo',
            'onclick_context': 'marketplace_market_square_400',
            'expandfile': 'logo_id',
            'mode': true,
            'label': "name",
            'append': 'logo_src'+(JsonP.item.length -1)+'var_'+index,
            'src': 'Logo_Image'+(JsonP.item.length -1)+'_'+index,
            'jsonID':'json' + index,
            'appendJson': JsonP.item.length -1,
            'appendJsonField': 'name',
            'experiment': true,
            'size': {
              'xl': 3,
              'md': 3,
              'sm': 2,
            }
          }, {
            'formType': 'input',
            'label': 'alt',
            'type': 'text',
            'value': '',
            'jsonID': 'json' + index,
            'id': 'alt' + (JsonP.item.length - 1) + 'var_' + index,
            'appendJson': JsonP.item.length - 1,
            'appendJsonField': 'alt',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            },
          },
          {
            'formType': 'textarea',
            'label': 'text',
            'type': 'textarea',
            'value': '',
            'jsonID': 'json' + index,
            'id': 'text' + (JsonP.item.length - 1) + 'var_' + index,
            'appendJson': JsonP.item.length - 1,
            'appendJsonField': 'text',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            },
          },
          {
            'formType': 'input',
            'label': 'link',
            'type': 'text',
            'value': '',
            'jsonID':'json' + index,
            'id':'link'+(JsonP.item.length -1)+'var_' + index,
            'appendJson': JsonP.item.length -1,
            'appendJsonField': 'link',
            'size': {
              'xl': 1,
              'md': 1,
              'sm': 1,
            },
      },
          {
            'formType': 'button',
            'label': 'Slide entfernen',
            'variants': 'danger',
            'removeID':JsonP.item.length -1,
            'removeIndex':index,
            'namespace':'slide',
            'jsonID':'json' + index,
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          },
          {
            'formType': 'input',
            'name': 'name',
            'visible': true,
            'label': 'Logo src',
            'type': 'hidden',
            'id':'logo_src'+(JsonP.item.length -1)+'var_'+index,
            'placeholder': 'imageID',
            'value': ''
          }

        ]
      }
    ]

   ;
    let newElement = <>{PielersForm(conf)}</>;
    let temp = document.createElement('div');
    ReactDOM.render(newElement, temp);
    let ele =  document.getElementById('uncontrolled-tab-example-tabpane-slides'+index);
    if(ele !== null) {
      ele.appendChild(temp)
    }
  }
  const buildSlider = (slidesItems:any, Appendclass_carousel:any, name:any, Appendid:any, innercarousel:any,setintervall:any) => {
    let slides = slidesItems;




    let row = '';
    let body = [];



    for (let x = 0; x < slides.item.length; x++) {
      row +=
          '<div id="' + name + '" class="carousel slide ' + Appendclass_carousel + '" data-ride="carousel" data-interval="' + setintervall + '">\n' +
          '    <ol class="carousel-indicators">\n'
      ;

      for (let x = 0; x < slides.item.length; x++) {
        if (x === 0) {
          row +='<li data-target="#' + name + '" data-slide-to="' + x + '" class="active"></li>';
        } else {
          row +='<li data-target="#' + name + '" data-slide-to="' + x + '"></li>';
        }
      }

      row +=
          '    </ol>\n' +
          '    <div class="carousel-inner ' + innercarousel + '">'
      ;

      for (let x = 0; x < slides.item.length; x++) {
        if (x === 0) {
          row +='<div class="carousel-item active">' + (slides.item[x].link !== "" ? '<a target="_blank" href="' + slides.item[x].link + '">' : '') + '<img class="d-block h-100" src="' + slides.item[x].name + '" alt="' + slides.item[x].alt + '">' + (slides.item[x].text !== "" ? '<p class="sliderText_' + Appendid + '">' + slides.item[x].text + '</p>' : '') + '' + (slides.item[x].link !== "" ? '</a>' : '') + '</div>\n';
        } else {
          row +='<div class="carousel-item">' + (slides.item[x].link !== "" ? '<a target="_blank" href="' + slides.item[x].link + '">' : '') + '<img class="d-block h-100"  src="' + slides.item[x].name + '" alt="' + slides.item[x].alt + '">' + (slides.item[x].text !== "" ? '<p class="sliderText_' + Appendid + '">' + slides.item[x].text + '</p>' : '') + '' + (slides.item[x].link !== "" ? '</a>' : '') + '</div>\n';
        }
      }

      row +=
          ' </div>\n' +
          '    <a class="carousel-control-prev" href="#' + name + '" role="button" data-slide="prev">\n' +
          '        <span class="carousel-control-prev-icon" aria-hidden="true"></span>\n' +
          '        <span class="sr-only"></span>\n' +
          '    </a>\n' +
          '    <a class="carousel-control-next" href="#' + name + '" role="button" data-slide="next">\n' +
          '       <span class="sr-only"></span>\n' +
          '        <span class="carousel-control-next-icon" aria-hidden="true"></span>\n' +
          '    </a>'
      ;


    }
    return row;

  }
  return (
    <>
      <Row  id={'slider'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>carouselItem_end</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.slider" />,'slider',(newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.slider" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
        <Col  className={'accordion-body'}>
          <div id={'preview-container_slider'+index} className={'preview-container'} >
            {(newElement === false ? <div id={"slider_"+ element.div_id+"_"+ element.id }
                 className={ element.class }
                 dangerouslySetInnerHTML={{__html: buildSlider(element.translation.de, element.translation.de.class_carousel, element.translation.de.name,
                 "slider_"+ element.div_id +"_"+ element.id +"",element.translation.de.innercarousel,element.translation.de.intervall )}}
            >



          </div>: <></>)}
          </div>
          <div id={'edit_slider'+index} style={{display:'none'}}>
           <Tabs defaultActiveKey={"slides"+index} id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey={"slides"+index} title={<IntlMessage Store={Store} messageId="settings.settings.slides" locale={'de'} />} key={"slides"+index} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                <Button variant={'success'} onClick={()=>JSONNewAppend()}>Neuen Slide hinzufügen</Button>
                {buildItem(element,index,newElement)}
              </Tab>
            </Tabs>

          </div>
        </Col>
        <div id={'settingstext'+index+'slider'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
            <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
              {PielersForm(newConfiguration)}
            </Tab>
            <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
              {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
            </Tab>
          </Tabs>
        </div>
            {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.slider"  Store={Store}/>,Store) : <></>)}

          </Row>

    </>
  )
}

export default sliderWebBuilder;


const buildItem = (element:any,index:any,newElement:any) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;

    let defaultJson = {"item": elementItem}




    if (elementItem !== undefined) {

      for (let x = 0; x < elementItem.length; x++) {
        Items.push(
            {
              'formType': 'multiply',
              'RowId':'slide'+x+'_'+index,
              'cols': [
                {
                  'formType': 'image',
                  'altLink': true,
                  'id': 'Logo_Image'+x+'_'+index,
                  'path': elementItem[x].name,
                  'width': '44',
                  'height': '44',
                  'class': 'elementsIMg',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                }, {
                  'formType': 'formFile',
                  'onclick': true,
                  'id': 'logo',
                  'onclick_context': 'marketplace_market_square_400',
                  'expandfile': 'logo_id',
                  'mode': true,
                  'label': "name",
                  'append': 'logo_src'+x+'var_'+index,
                  'src': 'Logo_Image'+x+'_'+index,
                  'jsonID':'json' + index,
                  'appendJson': x,
                  'appendJsonField': 'name',
                  'experiment': true,
                  'size': {
                    'xl': 3,
                    'md': 3,
                    'sm': 2,
                  }
                },{
                  'formType': 'input',
                  'label': 'alt',
                  'type': 'text',
                  'value': elementItem[x].alt,
                  'jsonID':'json' + index,
                  'id':'alt'+x+'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'alt',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
                {
                  'formType': 'textarea',
                  'label': 'text',
                  'type': 'textarea',
                  'value': elementItem[x].text,
                  'jsonID': 'json' + index,
                  'id': 'text' + x + 'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'text',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  },
                },
                {
                  'formType': 'input',
                  'label': 'link',
                  'type': 'text',
                  'value': elementItem[x].link,
                  'jsonID':'json' + index,
                  'id':'link'+x+'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'link',
                  'size': {
                    'xl': 1,
                    'md': 1,
                    'sm': 1,
                  },
                },
                {
                  'formType': 'button',
                  'label': 'Slide entfernen',
                  'variants': 'danger',
                  'removeID':x,
                  'namespace':'slide',
                  'removeIndex':index,
                  'jsonID':'json' + index,
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
                {
                  'formType': 'input',
                  'name': 'name',
                  'visible': true,
                  'label': 'Logo src',
                  'type': 'hidden',
                  'id':'logo_src'+x+'var_'+index,
                  'placeholder': 'imageID',
                  'value': elementItem[x].name
                }
              ]
            }
        )

      }
    }
      Items.push({
        'formType': 'input',
        'label': 'json',
        'id':'json' + index,
        'name': 'elements[' + index + '][translation][ItemString]',
        'type': 'hidden',
        'placeholder': "lang[common.insert_headline]",
        'value': JSON.stringify(defaultJson),
      });

      let newConfiguration = Items;

      return PielersForm(newConfiguration)


  } else {
    Items.push({
      'formType': 'input',
      'label': 'json',
      'id':'json' + index,
      'name': 'elements[' + index + '][translation][ItemString]',
      'type': 'hidden',
      'placeholder': "lang[common.insert_headline]",
      'value': '{"item":[]}',
    });

    let newConfiguration = Items;

    return PielersForm(newConfiguration)
  }

}
