import React from 'react';
import IntlMessage, {IntlMessageValue} from '../../../../../../@WUM/core/component/const/IntlMessage';


const configuration = (conf: string, response: any, newElement: any = false,Store:any) => {

  let config = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.name" />,
          'type': 'text',
          'required':true,
          'placeholder': IntlMessageValue('de','common.insert_name',Store),
          'name': 'name',
          'value': (newElement === false ? response.name : ''),
        },
        {
          'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.slug" />,
          'type': 'text',
          'required':true,
          'placeholder': IntlMessageValue('de','common.slug.placeholder',Store),
          'name': 'rate[code]',
          'value': (newElement === false ? response.rate.code : ''),
        },
        {
          'formType': 'input',
          'type': 'text',
          'label': <IntlMessage Store={Store} messageId="common.percent_value" />,
          'required':true,
          'placeholder': IntlMessageValue('de','tax.percent_value.legend',Store),
          'name': 'rate[amount]',
          'value': (newElement === false ? response.rate.amount : ''),
        },
        {
          'formType': 'input',
          'type': 'text',
          'label': <IntlMessage Store={Store} messageId="common.code" />,
          'required':true,
          'placeholder': IntlMessageValue('de','tax.code.legend',Store),
          'name': 'code',
          'value': (newElement === false ? response.code : ''),
        },
      ],
    },
    {
      'formType': 'textarea',
      'label': <IntlMessage Store={Store} messageId="common.description" />,
      'type': 'textarea',
      'row': 3,
      'name': 'description',
      'value': (newElement === false ? response.description : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'rate[category_id]',
      'value': (newElement === false ? response.id : ''),
    },

    {
      'formType': 'input',
      'label': 'zone_id',
      'type': 'hidden',
      'placeholder': IntlMessageValue('de','tax.zone_id.legend',Store),
      'name': 'rate[zone_id]',
      'value': (newElement === false ? response.rate.zone_id : '1'),
    },
  ];

  return config;

}

export default configuration;
