import {useEffect, useState} from 'react';
import {allTaxonApiType} from '../../request/taxon/allTacxonApi.interface';
import {Taxon} from '../../request/taxon/Taxon';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseallTaxonLimit = (page: any, searchValue: any) => {
  const [posts, setPosts] = useState<allTaxonApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Taxon.getallTaxonLimit(page,searchValue)
        .then((data) => {
          checkLogin(data);
          setPosts(data);
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [page,searchValue])


  return isError ? (isError ) : (posts);

}
export const ResponseallTaxonDetail = (page:any) => {
  const [posts, setPosts] = useState<allTaxonApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Taxon.getAPost(page)
        .then((data) => {
          checkLogin(data);
          setPosts(data);
          //console.log(data)
        })
        .catch((err) => {
          error(err);
          //console.log(err)
          setIsError(true);
        });
    })()}, [page])


  return isError ? (isError ) : (posts);

}

export const ResponseallTaxonTree = () => {
  const [posts, setPosts] = useState<allTaxonApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Taxon.getallTaxonTree()
        .then((data) => {
          checkLogin(data);
          setPosts(data);
        })
        .catch((err) => {
        error(err);
          setIsError(true);
        });
    })()}, [])


  return isError ? (isError ) : (posts);

}




