import IntlMessage, {IntlMessageValue} from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from 'react';

function configuration(conf: string, response: any, newElement: any = false,Store:any) {

  let config = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.name" />,
          'type': 'text',
          'required':true,
          'placeholder': IntlMessageValue('de','common.insert_name',Store),
          'name': 'translation[name]',
          'value': (newElement === false ? response.translation.name : ''),
        },
        {
          'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.taxon.code" />,
          'type': (newElement === false ? 'text' : 'text'),
          'name': 'code',
          'value': (newElement === false ? response.code : ''),
        },
        {
          'formType': 'input',
          'label': IntlMessageValue('de','common.position',Store),
          'type': 'text',
          'placeholder': IntlMessageValue('de','common.position.description.number',Store),
          'name': 'position',
          'value': (newElement === false ? response.position : ''),
        },
      ],
    },
    {
      'formType': 'textarea',
      'label': <IntlMessage Store={Store} messageId="common.description" />,
      'type': 'textarea',
      'row': 3,
      'name': 'translation[description]',
      'value': (newElement === false ? response.translation.description : ''),
    },
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'select',
          'label': <IntlMessage Store={Store} messageId="common.show.in.navigation" />,
          'required':true,
          'name': 'show_in_navigation',
          'selected': (newElement === false ? response.show_in_navigation : '1'),
          'options': [
            {
              'label': IntlMessageValue('de','common.display',Store),
              'value': '1',
            },
            {
              'label': IntlMessageValue('de','common.not.display',Store),
              'value': '0',
            }],

        },
        {
          'formType': 'fetchselect',
          'local': 'allTaxon',
          'valueState': 'id',
          'labelState': 'name',
          'beginn': 'true',
          'start': 0,
          'name': 'tree_root',
          'selected': (newElement === false ? response.tree_root : ''),
          'label': IntlMessageValue('de','category.main',Store),
        },
        {
          'formType': 'fetchselect',
          'local': 'allTaxon',
          'valueState': 'id',
          'labelState': 'name',
          'beginn': 'true',
          'start': 0,
          'name': 'parent_id',
          'selected': (newElement === false ? response.parent_id : ''),
          'label': IntlMessageValue('de','category.subs',Store),
        },
      ],
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.slug" />,
      'type': 'hidden',
      'required':true,
      'placeholder': IntlMessageValue('de','common.slug.placeholder',Store),
      'name': 'translation[slug]',
      'value': (newElement === false ? response.translation.slug : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'tree_level',
      'value': (newElement === false ? response.tree_level : ''),
    },
  ];

  let Configmerge = [
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : null),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translation[id]',
      'value': (newElement === false ? response.translation.id : null),
    },

  ];
  let configs;
  if(newElement === false) {
    configs = [
      ...config,
      ...Configmerge
  ]
  } else {
    configs = config;
  }
  return configs;

}

export default configuration;
