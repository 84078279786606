import React, {useState} from 'react';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {useNavigate, useParams} from 'react-router-dom';
import ContentSwitch from '../../../../@WUM/core/component/const/ContentSwitch';
import {
  ResponseallShippingLimit,
  ResponseShippingDelete,
} from '../API/response/shipping/Shipping';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";


const ShippingOverview = (value:any) => {

  const {page,deleteID} = useParams<{page: string,deleteID: string}>();
  let limit: any;
  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  }

  const Store = useSelector((state: any) => state)
  const history = useNavigate();

  /**
   * Neuladen der Seite
   */
  const routeChange = (paths:string) => {
    history(-1);
  };


  if(deleteID) {
    ResponseShippingDelete(deleteID);
    routeChange('/Shipping/overview/1')
  }
  const debug = (page: string | undefined, searchValue: string, modalShow: boolean, modalData: any[]) => {
    return;
  }
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState<boolean>(false)
  const response = ResponseallShippingLimit(limit,searchValue);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  debug(page,searchValue,modalShow,modalData)
  const [mode, setMode] = useState('cart');
  const destination = 'Shipping';

  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'img': '',
      'dontStatus': true,
      'dontStatus_label': <IntlMessage Store={Store} messageId="shipping.label" />,
      'status': 'visible',
      'statusActive': <IntlMessage Store={Store} messageId="common.activated" />,
      'statusDeactive': <IntlMessage Store={Store} messageId="common.deactivated" />,
      'link': 'slug',
      'countProducts': true,
      'prelink': 'http://myhomice-f2.pielers.de/magazin/[param]/',
      'prelinksearch': 'translation.slug',
      'title': 'name',
      'description': 'description',
      'router': 'id',
      'preroute': '/Shipping/Detail/',
      'edit': <IntlMessage Store={Store} messageId="shipping.edit" />,
      'delete': true,
      'deleteLink': '/Shipping/Delete/'+page+'/[ID]',
      'Dontvisited': true,
    }
  }

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['id', 'name', 'description'],
      'type': ['string', 'string', 'string'],
    },
    'tableHeader': [<IntlMessage Store={Store} messageId="common.id" />, <IntlMessage Store={Store} messageId="common.title" />,<IntlMessage Store={Store} messageId="common.description" />],
    'tableHeaderWidth': ['30%', '30%', '30%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/Shipping/Detail', '/Shipping/Delete'],
      'menuParams': ['shyippingType.id', 'shyippingType.id'],
      'menuIcons': ['pencil','Delete'],
    },

  };

  if ( response !== true && response.length > 0) {
    return (
      <>
        <ContentSwitch pagenew={'new'} destinationnew={'Shipping'} tonew={'new'} text={<IntlMessage Store={Store} messageId="common.create_new_shipping" />}
                       response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                       Configuration={Configuration} setMode={setMode} destination={destination} page={page}
                       to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
                       modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData}
                       searchfield={''} allTaxon={''} _handleKeyFilters={''} _handleEnter={''} filter={false} Store={Store}/>

  </>

    )
  } else {
    localStorage.setItem('searchEnterInput','');
        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default ShippingOverview;



