import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize';
import {config, output} from './configuration/mapping';
import PielersForm from '../../../../@WUM/core/form-serilizer';
import configuration from './configuration/Page/configuration';
import {Taxon} from '../API/request/taxon/Taxon';
import {notify_save} from '../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../@WUM/core/component/const/error.services';
import {handleSubmitForm} from '../../../../@WUM/core/component/const/handleSubmitForm';
import {Form} from 'react-bootstrap';
import {allTaxonApiType} from '../API/request/taxon/allTacxonApi.interface';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";



const TaxonNew = (value:any) => {
  const Store = useSelector((state: any) => state)

  const history = useNavigate();

  const [currentTutorial, setCurrentTutorial] = useState<allTaxonApiType[]>([]);
  const [validated, setValidated] = useState(false);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any = 0) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init();
    let reauestData: allTaxonApiType[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));
    //console.log(Data)
    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    Taxon.updatePostNew(reauestData, 0)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        history(-1);
      })
      .catch(e => {
        error(e);
      });

  };


  return (
    <div>
      <Form  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {PielersForm(configuration('dataColOne', [], true,Store))}
          </Tab>
        </Tabs>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
          <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
          <Button variant='success'
                  style={{float: 'right', marginRight: '15px'}}
                  type={'submit'}
          >
            <IntlMessage Store={Store} messageId="common.save" />
          </Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default TaxonNew;
