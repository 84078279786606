import {Rate} from '../../../../shop/products/API/request/Product/TaxApiDetail.interface';

export interface config {
  name: string;
  description: string;
  code: string;
  rate: Rate[];
}

export function output(Data: any) {
  let back = {
    name: Data.name,
    description: Data.description,
    code: Data.code,
    rate: Data.rate,


  }

  return back;
}