import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  useNavigate, useParams,
  //useParams,
} from 'react-router-dom';
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize';
import {config, output} from './configuration/mapping';
import {ResponseallTaxonDetail} from '../API/response/taxon/Taxon';
import {allTaxonApiType} from '../API/request/taxon/allTacxonApi.interface';
import PielersForm from '../../../../@WUM/core/form-serilizer';
import configuration from './configuration/Page/configuration';
import Spinner from 'react-bootstrap/Spinner';
import {Taxon} from '../API/request/taxon/Taxon';
import {notify_save} from '../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../@WUM/core/component/const/error.services';
//import {AllMerchant} from '../../../../@WUM/core/component/const/AllMerchant';
//import {AllProduct} from '../../../../@WUM/core/component/const/AllProduct';
import {handleSubmitForm} from '../../../../@WUM/core/component/const/handleSubmitForm';
import {Form} from 'react-bootstrap';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";



const TaxonDetail = (value:any) => {

  let {id} = useParams<{id: string}>();
  const response2 = ResponseallTaxonDetail(id);
  const Store = useSelector((state: any) => state)

  const history = useNavigate();


  const [currentTutorial, setCurrentTutorial] = useState<allTaxonApiType[]>((response2 !== true ? response2 : []));
  const [validated, setValidated] = useState(false);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init();

    //console.log(Data)

    let reauestData: allTaxonApiType[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));

    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    Taxon.updatePost(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        //routeChange(`/Taxon/overview/1`);
      })
      .catch(e => {
        error(e);
      });
  };


  if (response2.hasOwnProperty('translation') === true) {
    return (
      <div>
        <Form  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, id)}>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
            <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(configuration('dataColOne', response2, false,Store))}
            </Tab>
            <Tab eventKey="anbieter" title={<IntlMessage Store={Store} messageId="added.merchants.title" />} key={'anbieter'} id={'anbieter'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
              <h3><IntlMessage Store={Store} messageId="added.merchants.description" /></h3>
              {/*<AllMerchant response={response2} />*/}
            </Tab>
            <Tab eventKey="produkte" title={<IntlMessage Store={Store} messageId="added.products.title" />} key={'produkte'} id={'produkte'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
              <h3><IntlMessage Store={Store} messageId="added.products.description" /></h3>
              {/*<AllProduct response={response2} />*/}
            </Tab>
          </Tabs>
          <div style={{backgroundColor: 'white', padding: '15px'}}>
            <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
            <Button variant='success'
                    style={{float: 'right', marginRight: '15px'}}
                    type={'submit'}
            >
              <IntlMessage Store={Store} messageId="common.save" />
            </Button>
            <br style={{clear: 'both'}} />
          </div>
        </Form>
      </div>
    );
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner></>
    )
  }
}


export default TaxonDetail;
