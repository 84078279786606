import React from 'react';
import UnitOverview from "./Pages/overview";
import UnitDetail from "./Pages/Detail";
import UnitNew from "./Pages/new";
import {LanguageSwitcherValue} from "../../shop/core/components/LanguageSwitcher";

export const UnitPagesConfig = (value:any) => {

  return [
      {
        path: '/Unit/overview/:page',
        elements: <UnitOverview value={value} />,
      },
      {
        path: '/Unit/Detail/:id',
        elements: <UnitDetail value={value} />,
      },
      {
        path: '/Unit/Delete/:page/:deleteID',
        elements: <UnitOverview value={value} />,
      },
      {
        path: '/Unit/new/',
        elements: <UnitNew value={value} />,
      },
      {
          path: '/Unit/new/new',
          elements: <UnitNew value={value} />,
      },
];
}

export const  UnitMenuConfig = (value:any) => {
  return [
    {
      name: LanguageSwitcherValue("settings.unit.header"),
      route: '/Unit/overview/1',
      icon: 'scale'
    }
  ]
}
